import { useMemo } from 'react'
import { Typography } from 'antd'
import { Control, Controller } from 'react-hook-form'
import { EuroCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { t } from 'i18next'

import {
  ShareSelectInput,
  StyledBulletNumbering,
  WarningCard,
} from '@components'
import { Input } from 'src/common'
import { formatCurrencyByType } from 'src/utils/priceFormatter'
import { themes } from '@theme'
import {
  REGEX_TWO_DIGIT_AFTER_COMMA,
  WITHDRAWAL_LIMIT,
  WITHDRAWAL_STEP,
} from '@configs'

export const CreateWithdrawForm = ({
  control,
  bankAccount,
  withdrawLimit,
  available,
}: {
  control: Control<any>
  bankAccount?: string
  withdrawLimit?: number
  available?: number
}) => {
  const currencyValues = [
    {
      label: (
        <span className="flex items-center gap-2 !text-neutral-500">
          <EuroCircleOutlined className="!text-[24px]" />
          {t('revenue:eur')}
        </span>
      ),
      value: 'eur',
    },
  ]

  const inputList = useMemo(
    () => [
      {
        title: <TitleStyled>{t('revenue:currency')}</TitleStyled>,
        content: (
          <ContentWrapper>
            <ShareSelectInput
              containerClassName="!rounded-full"
              data={currencyValues}
              value={currencyValues[0].value}
              disabled
            />
            <WarningCard
              className="!px-2"
              content={t('revenue:exchange_rate')}
            />
          </ContentWrapper>
        ),
      },
      {
        title: <TitleStyled>{t('revenue:withdraw_to')}</TitleStyled>,
        content: (
          <ContentWrapper>
            <Input
              value={bankAccount ?? ''}
              label={t('revenue:bank_account')}
              alignment="col"
              disabled
            />
            <WarningCard
              className="!px-2"
              content={t('revenue:update_your_account')}
            />
          </ContentWrapper>
        ),
      },
      {
        title: <TitleStyled>{t('revenue:withdrawal_amount')}</TitleStyled>,
        content: (
          <ContentWrapper className="!gap-3">
            <div className="w-full flex justify-between">
              <Typography.Text className="!text-neutral-400">
                {t('revenue:withdrawal_amount')}
              </Typography.Text>

              <Typography.Text className="!text-neutral-400">
                {t('revenue:withdrawal_limit', {
                  amount: formatCurrencyByType(withdrawLimit ?? 0, 'EUR'),
                })}
              </Typography.Text>
            </div>
            <Controller
              control={control}
              name="amount"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Input
                  className="!text-neutral-400"
                  name="amount"
                  placeholder={t('revenue:minimum_withdrawal', {
                    amount: 10,
                  })}
                  value={value}
                  onChange={(event) => {
                    const value = event?.target?.value ?? 0
                    if (Number(value) < 0) {
                      onChange(0)
                      return
                    }

                    if (Number(value) > WITHDRAWAL_LIMIT) {
                      onChange(WITHDRAWAL_LIMIT)
                      return
                    }

                    if (
                      REGEX_TWO_DIGIT_AFTER_COMMA.test(value) ||
                      value === ''
                    ) {
                      onChange(value)
                    }
                  }}
                  onBlur={(event) => {
                    const value = Number(event?.target?.value ?? 0)
                    onChange(parseFloat(value.toFixed(2)))
                  }}
                  errors={error?.message}
                  suffix={t('revenue:eur')}
                  max={WITHDRAWAL_LIMIT}
                  step={WITHDRAWAL_STEP}
                  type="number"
                />
              )}
            />
            <div className="w-full flex justify-end ">
              <Typography.Text className="!text-neutral-400">
                {t('revenue:available', {
                  amount: formatCurrencyByType(available ?? 0, 'EUR'),
                })}
              </Typography.Text>
            </div>
          </ContentWrapper>
        ),
      },
    ],
    [available, bankAccount, control, withdrawLimit]
  )

  return (
    <CreateWithdrawFormStyled onSubmit={() => {}}>
      {inputList?.map((item, index) => (
        <InputWrapper key={index}>
          <span className="flex items-center gap-3 mb-4">
            <StyledBulletNumbering number={index + 1} />
            {item.title}
          </span>
          {item.content}
        </InputWrapper>
      ))}
    </CreateWithdrawFormStyled>
  )
}

const CreateWithdrawFormStyled = styled.form`
  width: 100%;

  .ant-input-disabled {
    color: ${themes.theme.light.colors.secondary};
  }
`

const TitleStyled = styled(Typography.Title)`
  font-size: 20px !important;
  font-weight: 700;
  margin-bottom: 0px !important;
`
const ContentWrapper = styled.div`
  width: 100%;
  gap: 21px;
  display: flex;
  flex-direction: column;

  .ant-select-selector,
  .ant-input-disabled,
  .ant-input {
    padding-left: 18px !important;
  }
`
const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`
