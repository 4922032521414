import { useGetSubscriptionQuery } from '@api'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading } from 'src/common'
import { SubscriptionPackages } from 'src/pages/subscription'
import styled from 'styled-components'
import CancelRenewalModal from './CancelRenewalModal'
import { PACKAGE_MANAGEMENT_SECTIONS } from './config'
import UpgradePlanForm from './UpgradePlanForm'
import { ISubscription } from '@interfaces'
import { useUpdatePackageRenewStatusMutation } from 'src/api/react-query/hooks/subscription/useUpdatePackageRenewStatus'
import { handleAPIError } from '@utils'
import { message } from 'antd'

export default function PackagamentManagementTab() {
  const { t } = useTranslation(['common'])
  const [isOpenCancelRenewalModal, setIsOpenCancelRenewalModal] =
    useState(false)

  const [packageTabSection, setPackageTabSection] = useState(
    PACKAGE_MANAGEMENT_SECTIONS.LIST_PACKAGES
  )
  const [selectedPackage, setSelectedPackage] = useState<ISubscription | null>(
    null
  )

  const {
    data: packageSubscriptionData,
    isLoading: isGetPackageSubscriptionLoading,
    refetchGetPackageSubscription,
  } = useGetSubscriptionQuery()

  const { mutate: updatePackageRenewStatus, isPending: isPackageRenewLoading } =
    useUpdatePackageRenewStatusMutation()

  const handleUpdatePackageRenewStatus = useCallback(
    async (packageId: string | number) => {
      if (!packageId) return

      try {
        await updatePackageRenewStatus(
          { packageId },
          {
            onError: (err) => {
              handleAPIError(err)
            },
            onSuccess: (res) => {
              message.success(res?.message)
              refetchGetPackageSubscription()
            },
          }
        )
      } catch (error: any) {
        handleAPIError(error)
      }
    },
    [refetchGetPackageSubscription, updatePackageRenewStatus]
  )

  const packageManagementSection = useMemo(() => {
    switch (packageTabSection) {
      case PACKAGE_MANAGEMENT_SECTIONS.LIST_PACKAGES:
        return (
          <SubscriptionPackages
            packages={packageSubscriptionData?.data?.packagesForTeacher}
            initialSlideIndex={packageSubscriptionData?.data?.packagesForTeacher?.findIndex(
              (it) => !!it?.packageSubscribed?.isActive && !!it?.isSubscribed
            )}
            title={t('common:package')}
            onSelectPackage={(data) => {
              setSelectedPackage(data as ISubscription)

              if (data?.isSubscribed && data?.packageSubscribed?.isActive) {
                if (!data?.packageSubscribed?.isAutoRenew) {
                  if (isPackageRenewLoading) return
                  handleUpdatePackageRenewStatus(data?.id)
                  return
                }

                setIsOpenCancelRenewalModal(true)
                return
              }

              if (data?.isSubscribed && data?.packageSubscribed?.isActive) {
                setIsOpenCancelRenewalModal(true)
                return
              }
              setPackageTabSection(
                PACKAGE_MANAGEMENT_SECTIONS.UPGRADE_PLAN_FORM
              )
            }}
          />
        )

      case PACKAGE_MANAGEMENT_SECTIONS.UPGRADE_PLAN_FORM:
        return (
          <UpgradePlanForm
            initialPackage={selectedPackage as ISubscription}
            packagesForTeacher={
              packageSubscriptionData?.data
                ?.packagesForTeacher as ISubscription[]
            }
            onCancelUpgrade={() =>
              setPackageTabSection(PACKAGE_MANAGEMENT_SECTIONS.LIST_PACKAGES)
            }
          />
        )

      default:
        return null
    }
  }, [
    handleUpdatePackageRenewStatus,
    isPackageRenewLoading,
    packageSubscriptionData?.data?.packagesForTeacher,
    packageTabSection,
    selectedPackage,
    t,
  ])

  if (isGetPackageSubscriptionLoading)
    return (
      <div className="bg-white w-full h-[60vh] flex items-center justify-center">
        <Loading />
      </div>
    )

  return (
    <>
      <SubscriptionPackagesStyled>
        {packageManagementSection}
      </SubscriptionPackagesStyled>

      <CancelRenewalModal
        open={isOpenCancelRenewalModal}
        onClose={() => setIsOpenCancelRenewalModal(false)}
        currentPackage={selectedPackage as ISubscription}
      />
    </>
  )
}

const SubscriptionPackagesStyled = styled.div`
  background-color: #ffffff;

  & > div {
    width: 100%;
    min-height: unset;
    padding: 40px;
  }

  .package-tag {
    max-height: 160px;
  }

  .swiper-container {
    width: 90%;
  }
`
